.root {
    width: 100%;
    padding: 0;
    display: block;
}

.text {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-base);
    margin-bottom: 4px;
}

.required {
    color: var(--secondary-400-base);
    font-weight: 700;
}

.errorWrapper {
    position: absolute;
    width: 90%;
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
    top: -4px;
    left: 100%;
    background-color: #ffff;
}

.errorText {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 1.75;
    letter-spacing: normal;
    color: var(--whitebase);
}

.colorIndicators {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 0.2rem;
}

.colorIndicators {
    height: 5px;
    margin: 2px 0;
}
.colorIndicators > span {
    border-radius: 5px;
}

.colorIndicators > .tooWeek {
    background-color: #cc5151;
}

.colorIndicators > .weak {
    background-color: #ffc966;
}

.colorIndicators > .medium {
    background-color: #ffff4c;
}

.colorIndicators > .strong {
    background-color: #007300;
}

.hintOptions > li.contains {
    color: #007300;
}

.hintOptions > li.error {
    color: #cc5151;
}

.hintOptions {
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin: 0;
}

.message {
    font-size: 12px;
}

.value {
    font-style: italic;
}

.tooWeek1 {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    background-color: #cc5151;
}

.weak1 {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    background-color: #ffc966;
}

.medium1 {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    background-color: #ffff66;
}

.strong1 {
    display: inline-block;
    border-radius: 5px;
    padding: 0 5px;
    background-color: #66b266;
}

/* .errorWrapper {
    width: 100%;
    background-color: var(--secondary-400-base-error);
    padding: 6px 14px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 16px 24px 0 rgba(48, 49, 51, 0.1);
} */

.container {
    position: relative;
}

/* ----------- iPhone 4 and 4S ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}
/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {
}

/* Portrait */
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
}

/* Portrait */
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) {
}

/* Portrait */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) and (orientation: landscape) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- Galaxy Tab 2 ----------- */

/* Portrait and Landscape */
@media (min-device-width: 800px) and (max-device-width: 1280px) {
}

/* Portrait */
@media (max-device-width: 800px) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media (max-device-width: 1280px) and (orientation: landscape) {
}

/* ----------- Galaxy Tab S ----------- */

/* Portrait and Landscape */
@media (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media (max-device-width: 800px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media (max-device-width: 1280px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- Nexus 7 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
}

/* Portrait */
@media screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: landscape) {
}

/* ----------- Nexus 9 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 1536px) and (device-height: 2048px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
}

/* Portrait */
@media screen and (device-width: 1536px) and (device-height: 2048px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media screen and (device-width: 1536px) and (device-height: 2048px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: landscape) {
}

/* ----------- Kindle Fire HD 7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

/* ----------- Kindle Fire HD 8.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
    .errorWrapper {
        position: relative;
        width: 100%;
        top: 0;
        left: 0;
    }
}

/* Landscape */
@media only screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}
