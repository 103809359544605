.root {
    width: 100%;
    background-color: var(--whitebase);
    min-height: 90px;
}

.wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 20px;
}

.text {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: var(--whitebase);
    padding: 0;
    margin: 0;
    width: 100%;
}

.social {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.social a {
    text-decoration: none;
    color: black;
}

.campusContainer {
    padding-bottom: 5px;
}

.campuses {
    color: black;
    text-align: center;
}

.campusContainer .name {
    font-size: 16px;
    color: var(--intellitec-red);
}

.campusContainer .code {
    font-size: 14px;
}

.campusContainer .address,
.campusContainer .phone {
    font-size: 12px;
}

.campuses h5 {
    margin: 0 0 5px 0;
    font-size: 16px;
}

.logo {
    width: 100%;
    height: 80px;
}

/* --tablet-widths */
@media (min-width: 660px) {
}

/* --desktop-widths */
@media (min-width: 1024px) {
    .wrapper {
        padding-top: 24px;
        padding-bottom: 22px;
    }

    .text {
        font-size: 14px;
    }

    .logo {
        width: 100%;
        height: 80px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
